<template>
  <div class="products">
    <div class="contents">
      <md-toolbar class="product-nav page-title">
        <md-icon class="navbar-icon1">
          <i class="fas fa-carrot"></i>
        </md-icon>
        <h1>Plots</h1>
      </md-toolbar>
      <div class="add-btn">
        <button
          class="gg-create-btn product-request-btn"
          @click="openPlotRequest = !openPlotRequest"
        >
          + Plot Request
        </button>
      </div>
      <h2>Table of Plot</h2>
      <PlotTable />
    </div>
    <ModalPlotRequest v-if="openPlotRequest" />
  </div>
</template>

<script>
// @ is an alias to /src
import PlotTable from "@/components/farmerPage/plots/PlotTable.vue";
import ModalPlotRequest from "@/components/farmerPage/plots/ModalPlotRequest.vue";
// import PartnerImageRequest from "@/components/PartnerImageRequest.vue";

export default {
  name: "ProductBatch",
  components: {
    PlotTable,
    ModalPlotRequest
    // PartnerImageRequest
  },
  data() {
    return {
      openPlotRequest: false
      // openImageRequest: false,
    };
  }
};
</script>
<style lang="scss" scoped>
.product-request-btn {
  margin: 0px 10px;
}
</style>
