<template>
  <div id="app" v-if="token == '' || token == null">
    <router-view></router-view>
  </div>

  <div class="page-container" v-else>
    <md-app>
      <md-app-toolbar class="md-primary md-toolbar-header header">
        <md-button class="md-icon-button" @click="toggleMenu" v-if="!menuVisible">
          <md-icon>menu</md-icon>
        </md-button>
        <div class="main-header" id="Main-header">
          <nav class="container">
            <ul>
              <li>
                <SearchComponentNew />
              </li>
              <li>
                <DropdownMenu class="navbar-dropdown" />
              </li>
              <!-- <li>
                <ModalForm />
              </li>-->
            </ul>
          </nav>
        </div>
      </md-app-toolbar>

      <md-app-drawer :md-active.sync="menuVisible" md-persistent="full">
        <md-toolbar class="md-transparent" md-elevation="0">
          <span class="md-display-1">GREENGROWTH</span>

          <div class="md-toolbar-section-end">
            <md-button class="md-icon-button md-dense" @click="toggleMenu">
              <md-icon>keyboard_arrow_left</md-icon>
            </md-button>
          </div>
        </md-toolbar>

        <md-list>
          <md-list-item>
            <md-avatar>
              <img
                v-if="profile_obj.image == 'http://18.138.188.241:1001/media/profile/default.jpg'"
                src="./assets/img/avatar.jpg"
                alt="avatar"
              />
              <img v-else :src="profile_obj.image" alt="avatar" />
            </md-avatar>
            <md-toolbar class="md-transparent" md-elevation="0">
              {{ profile_obj["full_name"] }}
              <!-- <span>Manager</span> -->
            </md-toolbar>
          </md-list-item>
          <md-list-item>
            <router-link to="/dashboard?page=1" class="sidebar-links">
              <span class="md-list-item-text">Dashboard</span>
              <i class="fas fa-home"></i>
            </router-link>
          </md-list-item>

          <md-list-item v-if="profile_obj.admin == true || role == 'Staff User'">
            <router-link to="/categories-page?page=1" class="sidebar-links">
              <span class="md-list-item-text">Categories</span>
              <i class="fas fa-th-large"></i>
            </router-link>
          </md-list-item>
          <md-list-item
            v-if="
              profile_obj.admin == true ||
              role == 'Staff User' ||
              role == 'Outlets' ||
              role == 'Farmer'
            "
          >
            <router-link to="/product-page" class="sidebar-links">
              <span class="md-list-item-text">Products</span>
              <i class="fas fa-layer-group"></i>
            </router-link>
          </md-list-item>

          <md-list-item
            v-if="profile_obj.admin == true || profile_obj.staff == true || role == 'Farmer'"
          >
            <router-link to="/product-batch?page=1" class="sidebar-links">
              <span class="md-list-item-text">Batch Products</span>
              <i class="fab fa-product-hunt"></i>
            </router-link>
          </md-list-item>

          <!-- <md-list-item>
            <router-link to="/track-order" class="sidebar-links">
              <span class="md-list-item-text">Track Order</span>
              <i class="fas fa-shopping-bag"></i>
            </router-link>
          </md-list-item> -->

          <md-list-item v-if="profile_obj.admin == true || role == 'Staff User'">
            <router-link to="/source-page?page=1" class="sidebar-links">
              <span class="md-list-item-text">Sources</span>
              <i class="fas fa-carrot"></i>
            </router-link>
          </md-list-item>
          <md-list-item
            v-if="
              profile_obj.admin == true ||
              role == 'Farmer' ||
              role == 'Outlets' ||
              role == 'Staff User'
            "
          >
            <router-link to="/inventry-page?page=1" class="sidebar-links">
              <span class="md-list-item-text">Inventory</span>
              <i class="fas fa-layer-group"></i>
            </router-link>
          </md-list-item>
          <md-list-item>
            <router-link to="/order-page?page=1" class="sidebar-links">
              <span class="md-list-item-text">Orders</span>
              <i class="fas fa-shopping-bag"></i>
            </router-link>
          </md-list-item>

          <md-list-item
            v-if="profile_obj.admin == true || role == 'Delivery User' || role == 'Staff User'"
          >
            <router-link to="/deliveries-page?page=1" class="sidebar-links">
              <span class="md-list-item-text">Deliveries</span>
              <i class="fas fa-shopping-cart"></i>
            </router-link>
          </md-list-item>

          <!-- <md-list-item>
            <router-link to="/deliveries-map-page" class="sidebar-links">
              <span class="md-list-item-text">Deliveries-Map</span>
              <i class="fas fa-map-marked-alt"></i>
            </router-link>
          </md-list-item> -->

          <md-list-item
            v-if="
              role == 'Web User' ||
              role == 'Mobile User' ||
              role == 'Delivery User' ||
              role == 'Staff User' ||
              profile_obj.admin == true
            "
          >
            <router-link to="/comments-page?page=1" class="sidebar-links">
              <span class="md-list-item-text">Comments</span>
              <i class="fas fa-comments"></i>
            </router-link>
          </md-list-item>

          <md-list-item
            v-if="profile_obj.admin == true || role == 'Outlets' || role == 'Staff User'"
          >
            <router-link to="/outlets-page?page=1" class="sidebar-links">
              <span class="md-list-item-text">Outlets</span>
              <i class="fas fa-sign-out-alt"></i>
            </router-link>
          </md-list-item>
          <md-list-item v-if="profile_obj.admin == true || role == 'Staff User'">
            <router-link to="/members-page?page=1" class="sidebar-links">
              <span class="md-list-item-text">Members</span>
              <i class="fas fa-user-plus"></i>
            </router-link>
          </md-list-item>
          <!-- <md-list-item
            v-if="profile_obj.admin == true || role == 'Staff User'"
          >
            <router-link to="/cooperative-page?page=1" class="sidebar-links">
              <span class="md-list-item-text">Cooperatives</span>
              <i class="fas fa-user-plus"></i>
            </router-link>
          </md-list-item> -->
          <md-list-item v-if="profile_obj.admin == 'true' || role == 'Staff User'">
            <router-link to="/customers-page?page=1" class="sidebar-links">
              <span class="md-list-item-text">Customers</span>
              <i class="fas fa-users"></i>
            </router-link>
          </md-list-item>
          <md-list-item>
            <router-link to="/notification-page?page=1" class="sidebar-links">
              <span class="md-list-item-text">Notifications</span>
              <i class="fas fa-bell"></i>
            </router-link>
          </md-list-item>
          <md-list-item
            v-if="profile_obj.admin == true || role == 'Outlets' || role == 'Staff User'"
          >
            <router-link to="/request-page?page=1" class="sidebar-links">
              <span class="md-list-item-text">Requests</span>
              <i class="fas fa-share"></i>
            </router-link>
          </md-list-item>
          <md-list-item v-if="profile_obj.admin == true || role == 'Staff User'">
            <router-link to="/source-product-page?page=1" class="sidebar-links">
              <span class="md-list-item-text">Source/Products</span>
              <i class="fas fa-carrot"></i>
            </router-link>
          </md-list-item>

          <md-list-item v-if="profile_obj.admin == true || role == 'Staff User'">
            <router-link :to="{ name: 'unitPage', query: { page: 1 } }" class="sidebar-links">
              <span class="md-list-item-text">Add Unit</span>
              <i class="fas fa-balance-scale"></i>
            </router-link>
          </md-list-item>

          <md-list-item v-if="profile_obj.admin == true || role == 'Staff User'">
            <router-link :to="{ name: 'brandPage', query: { page: 1 } }" class="sidebar-links">
              <span class="md-list-item-text">Add Brand</span>
              <i class="fas fa-tag"></i>
            </router-link>
          </md-list-item>

          <md-list-item v-if="profile_obj.admin == true || role == 'Farmer'">
            <router-link :to="{ name: 'sitePage', query: { page: 1 } }" class="sidebar-links">
              <span class="md-list-item-text">Sites</span>
              <i class="fas fa-tag"></i>
            </router-link>
          </md-list-item>

          <md-list-item v-if="profile_obj.admin == true || role == 'Farmer'">
            <router-link :to="{ name: 'plotPage', query: { page: 1 } }" class="sidebar-links">
              <span class="md-list-item-text">Plots</span>
              <i class="fas fa-tractor"></i>
            </router-link>
          </md-list-item>

          <md-list-item v-if="profile_obj.admin == true || role == 'Farmer'">
            <router-link
              :to="{ name: 'PlantSeedSource', query: { page: 1 } }"
              class="sidebar-links"
            >
              <span class="md-list-item-text">Plant Seed Source</span>
              <i class="fas fa-seedling"></i>
            </router-link>
          </md-list-item>

          <md-list-item v-if="profile_obj.admin == true || role == 'Farmer'">
            <router-link :to="{ name: 'cropPage', query: { page: 1 } }" class="sidebar-links">
              <span class="md-list-item-text">Crop Type</span>
              <i class="fas fa-seedling"></i>
            </router-link>
          </md-list-item>

          <md-list-item v-if="profile_obj.admin == true || role == 'Farmer'">
            <router-link :to="{ name: 'FarmerTaskList', query: { page: 1 } }" class="sidebar-links">
              <span class="md-list-item-text">Farmer Task List</span>
              <i class="fas fa-seedling"></i>
            </router-link>
          </md-list-item>

          <md-list-item v-if="profile_obj.admin == true || role == 'Staff User'">
            <router-link :to="{ name: 'bannerpage', query: { page: 1 } }" class="sidebar-links">
              <span class="md-list-item-text">Add Banner</span>
              <i class="far fa-images"></i>
            </router-link>
          </md-list-item>
          <md-list-item v-if="profile_obj.admin == true || role == 'Staff User'">
            <router-link :to="{ name: 'partnerpage', query: { page: 1 } }" class="sidebar-links">
              <span class="md-list-item-text">Add Partner</span>
              <i class="far fa-images"></i>
            </router-link>
          </md-list-item>

          <!-- <md-list-item>
            <md-button
              class="md-primary md-raised dialog-button"
              @click="openDialogProduct = !openDialogProduct"
            >Add Products</md-button>
          </md-list-item>
          <md-list-item>
            <md-button
              class="md-primary md-raised dialog-button"
              @click="openDialogRequest = !openDialogRequest"
            >Make Request</md-button>
          </md-list-item>-->
        </md-list>
      </md-app-drawer>
      <md-app-content>
        <router-view></router-view>
      </md-app-content>
    </md-app>
    <!-- <ModalProduct v-if="openDialogProduct" /> -->
    <!-- <ModalRequest v-if="openDialogRequest" /> -->
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
// import SearchComponent from "@/components/SearchComponent.vue";
import SearchComponentNew from "@/components/SearchComponentNew.vue";
import DropdownMenu from "@/components/DropdownMenu.vue";
// import ModalProduct from "@/components/ModalProduct.vue";
// import ModalRequest from "@/components/ModalRequest.vue";

export default {
  name: "PersistentFull",
  components: {
    SearchComponentNew,
    DropdownMenu,
    // ModalProduct,
    // ModalRequest
  },

  computed: {
    ...mapState(["token", "profile_obj"]),
  },

  created() {
    this.listProfile();
  },

  watch: {
    token: function () {
      //console.log("pk");
      //console.log(this.token);
      if (this.$store.state.token == "" || this.$store.state.token == null || this.token == null) {
        this.$router.replace("/logout");
      }
    },

    profile_obj: function () {
      if (this.profile_obj.group) {
        this.role = this.profile_obj.group.name;
      }
    },
  },

  mounted: function () {
    // const mapbox_style = document.createElement('link')
    // mapbox_style.type = "text/css"
    // mapbox_style.rel = "stylesheet"
    // mapbox_style.href = 'https://api.mapbox.com/mapbox-gl-js/v1.11.0/mapbox-gl.css'
    // mapbox_style.defer = true
    // document.head.appendChild(mapbox_style)

    // const mapbox_script = document.createElement('script');
    // mapbox_script.src  = 'https://api.mapbox.com/mapbox-gl-js/v1.11.0/mapbox-gl.js';
    // mapbox_script.type = 'text/javascript';
    // mapbox_script.defer = true;
    // document.head.appendChild(mapbox_script);
    //
    //
    // const baato_script = document.createElement('script');
    // baato_script.src  = 'https://sgp1.digitaloceanspaces.com/baatocdn/baato-0.0.1.js';
    // baato_script.type = 'text/javascript';
    // baato_script.defer = true;
    // document.head.appendChild(baato_script);

    console.log("VUE_APP_BASE_URL", process.env.VUE_APP_BASE_URL);
    console.log("VUE_APP_API_BASE_IP", process.env.VUE_APP_API_BASE_IP);
    console.log("VUE_APP_SERVICE_URL", process.env.VUE_APP_SERVICE_URL);
    console.log("VUE_APP_LOGOUT_REDIRECT", process.env.VUE_APP_USER_PANEL);
    console.log("VUE_APP_LOGIN_REDIRECT", process.env.VUE_APP_ADMIN_PANEL);
  },
  data: () => ({
    // menuVisible: true,
    menuVisible: false,
    role: "",
    // openDialogProduct: false,
    // openDialogRequest: false
  }),

  methods: {
    ...mapActions(["listProfile"]),
    ...mapMutations(["setToken"]),

    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
  },
};
</script>
<style lang="scss">
@import "https://api.mapbox.com/mapbox-gl-js/v1.11.0/mapbox-gl.css";
.dropdown-menu {
  width: 100%;
}

.batch-user {
  width: 100%;
  margin: 8px 0px;
}
.batch-dropdown {
  width: 100%;
  height: 100%;
  padding: 0px 0px;

  .md-button-content {
    padding: 16px 0px;
    text-decoration: none;
    color: #707070;
    i {
      font-size: 25px;
    }
  }

  &:hover {
    span {
      color: #4ba069 !important;
    }
    i {
      color: #4ba069 !important;
    }
  }
}
.md-button-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.batch-title {
  margin: 0 0.1rem;
}
</style>
