<template>
  <div class="main-form">
    <h2 class="form-title">Create Plot</h2>
    <label for="site_name">Select Site </label>
    <select id="site_name" v-model="select_site">
      <option value="" disabled>Select Site</option>
      <option v-for="(site, index) in product_site" :key="index" :value="site.id">
        {{ site.name }}
      </option>
    </select>
    <span class="text-danger float-right" style="color: red" v-if="errors.select_site">{{
      errors.select_site
    }}</span>
    <label for="plot_name">Plot Name *</label>
    <md-field md-clearable>
      <md-input id="plot_name" v-model="plot_name"></md-input>
    </md-field>
    <span class="text-danger float-right" style="color: red" v-if="errors.plot_name">{{
      errors.plot_name
    }}</span>

    <!-- <span class="text-danger float-right" style="color: red" v-if="errors.batch_product_name">{{
      errors.batch_product_name
    }}</span> -->

    <label for="plot_size">Plot Size*</label>
    <md-field md-clearable>
      <md-input id="plot_size" v-model="plot_size"></md-input>
    </md-field>
    <span class="text-danger float-right" style="color: red" v-if="errors.plot_size">{{
      errors.plot_size
    }}</span>

    <label for="plantation">plantation*</label>
    <md-field md-clearable>
      <md-input id="plantation" v-model="plantation"></md-input>
    </md-field>
    <span class="text-danger float-right" style="color: red" v-if="errors.plantation">{{
      errors.plantation
    }}</span>

    <label for="plot_yield">Plot Yield*</label>
    <md-field md-clearable>
      <md-input id="plot_yield" v-model="plot_yield"></md-input>
    </md-field>
    <span class="text-danger float-right" style="color: red" v-if="errors.plot_yield">{{
      errors.plot_yield
    }}</span>

    <label for="last_irrigation_date">Last Irrigation date</label>
    <md-field md-clearable>
      <md-input type="date" id="last_irrigation_date" v-model="last_irrigation_date"></md-input>
    </md-field>
    <span class="text-danger float-right" style="color: red" v-if="errors.last_irrigation_date">{{
      errors.last_irrigation_date
    }}</span>

    <label for="last_ploughing_date">Last Ploughing date*</label>
    <md-field md-clearable>
      <md-input type="date" id="last_ploughing_date" v-model="last_ploughing_date"></md-input>
    </md-field>
    <span class="text-danger float-right" style="color: red" v-if="errors.last_ploughing_date">{{
      errors.last_ploughing_date
    }}</span>

    <!-- <span style="color: red" v-if="error_message.length > 0">{{ response_message }}<br /></span>
    <span style="color: green" v-if="success_message.length > 0"
      >Plot Added Successfully.<br
    /></span> -->

    <input
      type="submit"
      @click="addPlot"
      value="Add New Plot"
      class="gg-create-btn"
      v-if="!isLoading"
    />
    <i class="fas fa-spinner" v-else></i>
    <!-- <md-progress-linear v-if="isLoading" md-mode="indeterminate"></md-progress-linear> -->
    <!-- <img v-else src="../assets/img/loader.gif" width="64" height="64" alt="Greengrowth Logo" /> -->
  </div>
</template>

<script>
// import axios from "axios";
import { mapState, mapActions } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    Loading
  },

  computed: {
    ...mapState([
      // "product_obj",
      // "product_category",
      // "product_unit",
      // "product_brand",
      // "all_user_obj",
      // "success_message",
      // "error_message",
      // "response_message",
      // "partner_obj",
      "product_site"
    ])
    // filterCategories() {
    //   let allCategories = this.product_category;
    //   allCategories = allCategories.filter(row => {
    //     return row.category_status == "Available";
    //   });

    //   return allCategories;
    // },
    // filterSourceUser() {
    //   let allusers = this.all_user_obj;
    //   allusers = allusers.filter(row => {
    //     return row.group.name == "Farmer";
    //   });

    //   return allusers;
    // },
    // filterOutletUser() {
    //   let allOutlet = this.all_user_obj;
    //   allOutlet = allOutlet.filter(row => {
    //     return row.group.name == "Outlets";
    //   });
    //   return allOutlet;
    // },
    // filterPartnerUser() {
    //   let allPartner = this.partner_obj;
    //   allPartner = allPartner.filter(row => {
    //     return row.name;
    //   });
    //   return allPartner;
    // },
    // filterProductUnit() {
    //   let allunits = this.product_unit;
    //   allunits = allunits.filter(row => {
    //     return row.status;
    //   });
    //   return allunits;
    // }
  },

  created() {
    // this.listProducts();
    // this.listCategories();
    // this.listUnits();
    // this.listBrands();
    // this.listUser();
    // this.listPartner();
    this.listSites();
  },

  data() {
    return {
      select_site: "",
      plot_name: "",
      plot_size: "",
      plantation: "",
      plot_yield: "",
      last_irrigation_date: "",
      last_ploughing_date: "",
      isLoading: false,
      errors: []

      // batch_source_name: "",
      // batch_product_name: "",
      // batch_partner_name: [],
      // batch_product_outlet: "",
      // batch_product_unit: "",
      // batch_source_crop: "",
      // batch_quantity: 0,
      // dispatch_last_date: "",
      // auto_generated_batch_id: "",
      // unit_obj: "",
      // availability_obj: "",
      // product_name: "",
      // nepali_name: "",
      // sku: "",
      // quantity: 1,
      // price_in: "",
      // price_out: "",
      // image: "",
      // validsku: "",
      // files: [],
      // file: "",
      // brand: null,
      // description: "",
      // nepali_description: "",
      // return_policy: "",
      // image_url: null,
      // is_exclusive: false,
      // currentDate: new Date().toISOString().substring(0, 10)
    };
  },

  methods: {
    ...mapActions([
      // "listProducts",
      // "listCategories",
      // "listUnits",
      // "listBrands",
      // "listUser",
      // "listPartner",
      "listSites"
    ]),
    // validateSKU() {
    //   if (this.sku.length > 0) {
    //     this.errors["sku"] = "";
    //     this.isLoading = true;
    //     axios.defaults.headers.common["authorization"] = "JWT " + localStorage.getItem("token");
    //     axios
    //       .post("skuvalidate", { sku: this.sku })
    //       .then(response => {
    //         this.isLoading = false;
    //         if (response.status == 200) {
    //           this.validsku = "yes";
    //         }
    //       })
    //       .catch(error => {
    //         this.isLoading = false;
    //         this.validsku = "no";
    //       });
    //   } else {
    //     this.validsku = "no";
    //   }
    // },
    addPlot() {
      this.errors = [];
      if (this.select_site == "") {
        this.errors["select_site"] = "Site Name required.";
      } else if (this.plot_name == "") {
        this.errors["plot_name"] = "Plot Name required.";
      } else if (this.plot_size == "") {
        this.errors["plot_size"] = "Plot Size required.";
      } else if (this.plantation == "") {
        this.errors["plantation"] = "Plantation required.";
      } else if (this.plot_yield == "") {
        this.errors["plot_yield"] = "Plant Yield required.";
      } else if (this.last_irrigation_date == "") {
        this.errors["last_irrigation_date"] = "Last irrigation Date required.";
      } else if (this.last_ploughing_date == "") {
        this.errors["last_ploughing_date"] = "Last Ploughing Date required.";
      } else {
        this.Loading = true;
        let formData = new FormData();
        formData.append("Site", this.select_site);
        formData.append("Name", this.plot_name);
        formData.append("plot_size", this.plot_size);
        formData.append("plantation", this.plantation);
        formData.append("plot_yield", this.plot_yield);
        formData.append("last_irrigation_date", this.last_irrigation_date);
        formData.append("last_ploughing_date", this.last_ploughing_date);
        this.$store.dispatch("createPlot", formData).then(() => {
          if (this.success_message == "success") {
            setTimeout(function() {
              setTimeout(() => {
                // location.reload();
              }, 100);
            }, 1000);
          }
        });
      }
    }
    // onChangeFileUpload() {
    //   this.file = this.$refs.file.files[0];
    //   this.image_url = URL.createObjectURL(this.file);
    // },
    // clearImage() {
    //   const input = this.$refs.file;
    //   input.type = "file";
    //   this.file = "";
    //   this.image_url = "";
    // }
  }
};
</script>

<style lang="scss">
.main-form {
  .upload-file-area {
    height: 120px;
    border: 1px dashed #4ba069;
    text-align: center;
    position: relative;
    .choose-button {
      background: #4ba069;
      padding: 10px 40px;
      color: #fff;
      border: none;
      margin-bottom: 20px;
    }
    .or {
      color: grey;
    }
    .drag-text {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      pointer-events: none;
    }
    input[type="file"] {
      border: none;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
  }
  .md-field {
    width: 100%;
    min-height: 48px;
    margin: 4px 0 0px;
    padding-top: 10px;
    display: flex;
    position: relative;
    font-family: inherit;
    .md-input {
      margin: 0.2rem 0;
      padding: 0.5rem;
      border: 1px solid #cbcbcb;
      border-radius: 5px;
    }
  }
}
</style>
