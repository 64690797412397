<template>
  <div class="products">
    <div class="contents">
      <span v-if="errors.site_add_error" style="color: red; font-weight: bold;"
        >{{ errors.site_add_error }}<br
      /></span>
      <h2>Today's Task</h2>
      <aside class="product-unit-form">
        <form class="create-post" @submit.prevent="addTask()">
          <label for="uname">Select Plot no *</label>
          <select id="site_name" v-model="select_plot">
            <option value="" disabled>Select Site</option>
            <option v-for="(plot, index) in plot_list" :key="index" :value="plot.id">
              {{ plot.name }}
            </option>
          </select>
          <span class="form-error" v-if="errors.task_name">{{ errors.task_name }}</span>
          <label for="uname">Add Task *</label>
          <input type="text" id="uname" v-model="task_name" placeholder="Enter here" />
          <span class="form-error" v-if="errors.task_name">{{ errors.task_name }}</span>
          <button class="post-btn">ADD</button>
          <span class="form-success" v-if="success">
            <md-icon class="md-size-2x">check_circle_outline</md-icon>
            {{ success }}
          </span>
        </form>
        <div class="order-detail-table-cost unit">
          <h3>Task List</h3>
          <ul>
            <li v-for="(task_name, index) in plot_list.slice(count_start, count_end)" :key="index">
              <!-- <li> -->
              {{ task_name.name }}
              <span>
                <button class="gg-create-btn" @click="openEditDialog(task_name.id)">Edit</button>
                <button class="gg-create-btn delete-button" @click="openDeleteDialog(task_name.id)">
                  Delete
                </button>
              </span>
              <!-- </li> -->
            </li>
          </ul>

          <div v-if="plot_list && plot_list.length">
            <ul class="nav-pagination">
              <li>
                <router-link
                  :to="{
                    name: current_route,
                    query: { page: current_page <= 1 ? 1 : current_page - 1 }
                  }"
                  >&laquo;</router-link
                >
              </li>

              <li
                v-for="(page, index) in Math.ceil(plot_list.length / page_sequence)"
                :class="current_page == page ? 'active' : ''"
                :key="index"
              >
                <router-link :to="{ name: current_route, query: { page: page } }">{{
                  page
                }}</router-link>
              </li>

              <li>
                <router-link
                  :to="{
                    name: current_route,
                    query: {
                      page:
                        current_page >= Math.ceil(plot_list.length / page_sequence)
                          ? Math.ceil(plot_list.length / page_sequence)
                          : current_page + 1
                    }
                  }"
                  >&raquo;</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </aside>
    </div>
    <md-dialog :md-active.sync="showDialog" class="modal-main">
      <div class="modal-form">
        <md-dialog-actions class="close-icon">
          <span class="material-icons error-icon" @click="showDialog = false">highlight_off</span>
        </md-dialog-actions>
        <h2 class="form-title">Edit Task</h2>
        <form class="main-form" @submit.prevent="editTask">
          <label for="name">Task name</label>
          <input type="text" class="product" v-model="edit_task" placeholder />
          <span class="form-error" v-if="errors.edit_brand">{{ errors.edit_brand }}</span>
          <button type="submit" class="gg-create-btn">Save</button>
          <span class="form-success" v-if="editSuccess">
            <md-icon class="md-size-2x">check_circle_outline</md-icon>
            {{ editSuccess }}
          </span>
        </form>
      </div>
    </md-dialog>
    <md-dialog :md-active.sync="showDialogDelete" class="modal-main">
      <div class="modal-form">
        <md-dialog-actions class="close-icon">
          <span class="material-icons error-icon" @click="showDialogDelete = false"
            >highlight_off</span
          >
        </md-dialog-actions>
        <div class="modal-alert">
          <div class="icon-div">
            <span class="material-icons error-icon">close</span>
          </div>
          <md-dialog-title class="modal-title">Are you sure to delete this Task?</md-dialog-title>
          <div class="buttons">
            <button type="submit" class="gg-create-btn delete-button" @click="deleteSite">
              Delete
            </button>
            <button type="submit" class="gg-create-btn" @click="showDialogDelete = false">
              Cancel
            </button>
          </div>
        </div>
        <!-- <div class="modal-alert">
          <div class="icon-div">
            <span class="material-icons error-icon">close</span>
          </div>
          <md-dialog-title class="modal-title">Product Deleted</md-dialog-title>
        </div>-->
      </div>
    </md-dialog>
    <input type="hidden" v-model="state_update_text" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "FarmerListPage",
  computed: {
    ...mapState(["plot_list", "success_message", "error_message", "response_message"])
  },
  created() {
    this.listPlot();
    // Pagination
    this.current_page =
      this.$route.query.page && this.$route.query.page > 0 ? this.$route.query.page : 1;
    this.count_start = (this.current_page - 1) * this.page_sequence;
    this.count_end = this.current_page * this.page_sequence;
  },
  watch: {
    "$route.query.page"() {
      this.current_page =
        this.$route.query.page && this.$route.query.page > 0 ? this.$route.query.page : 1;
      this.count_start = (this.current_page - 1) * this.page_sequence;
      this.count_end = this.current_page * this.page_sequence;
    }
  },
  data() {
    return {
      task_name: "",
      edit_task: "",
      emptySite: "",
      single_site: [],
      select_plot: "",
      errors: [],
      inputDisabled: true,
      showDialog: false,
      showDialogDelete: false,
      success: "",
      editSuccess: "",
      state_update_text: "",
      deleted: false,
      current_page: 1,
      current_route: this.$route.name,
      page_sequence: 10,
      count_start: 0,
      count_end: 10
    };
  },
  methods: {
    ...mapActions(["listPlot"]),
    addTask() {
      let date = new Date().toISOString().slice(0, 10);
      this.errors = [];
      if (this.task_name == "") {
        this.errors["task_name"] = "Please add today's task";
      } else {
        let formData = new FormData();
        formData.append("name", this.task_name);
        formData.append("plot_no", this.select_plot);
        formData.append("date", date);
        // for (var pair of formData.entries()) {
        //  console.log(pair[0]+ ', ' + pair[1]);
        // }

        // this.$store.dispatch("FarmerTaskList", formData).then(() => {
        //   if (this.success_message == "success") {
        //     this.task_name = "";
        //     this.success = "Task Added successfully";
        //     setTimeout(function() {
        //       location.reload();
        //     }, 1000);
        //   } else if (this.error_message == "errormessage") {
        //     this.state_update_text = "Task Name already exists.";
        //     this.errors["task_add_error"] = "Task Name already exists.";
        //   }
        // });
      }
    },
    openEditDialog(site_id) {
      this.single_site = this.product_site.find(row => row.id == site_id);
      this.edit_task = this.single_site.name;
      this.showDialog = true;
    },
    editTask() {
      this.errors = [];
      this.editSuccess = "";
      if (this.edit_task == "") {
        this.errors["edit_task"] = "Task  is required.";
      } else {
        this.$store
          .dispatch("updateSite", {
            name: this.edit_task,
            id: this.single_site.id
          })
          .then(() => {
            if (this.success_message == "success") {
              this.editSuccess = "Site name updated successfully";
              setTimeout(function() {
                location.reload();
              }, 500);
            } else if (this.error_message == "errormessage") {
              alert(this.response_message);
            }
          });
      }
    },
    openDeleteDialog(site_id) {
      this.single_site = this.product_site.find(row => row.id == site_id);
      this.showDialogDelete = true;
    },
    deleteSite() {
      if (this.single_site && this.single_site.id > 0) {
        this.showDialogDelete = false;
        this.$store.dispatch("deleteSite", this.single_site.id).then(() => {
          if (this.success_message == "success") {
            this.single_site = [];
            location.reload();
          } else if (this.error_message == "errormessage") {
            this.single_site = [];
            alert(this.response_message);
          }
        });
      }
    }
  }
};
</script>

<style scoped>
/** *** Pagination Menu Styling *** **/

select {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 5px;
  font-size: 14px;
  color: #000;
  margin-bottom: 10px;
}
.nav-pagination {
  list-style: none;
  float: left;
  width: 100%;
  padding: 10px;
}
.nav-pagination li {
  float: left;
  margin: 0 5px;
}
.nav-pagination li.active {
}
.nav-pagination li a {
  padding: 3px 8px;
  background: #e6e6e6;
  border: 1px #3b844a solid;
  border-radius: 6px;
  color: #3b844a;
  text-decoration: none;
  font-size: 14px;
  transition-duration: 1s;
}
.nav-pagination li a:hover {
  background: #3b844a;
  color: #ffffff;
  transition-duration: 1s;
}
.nav-pagination li.active a {
  background: #3b844a;
  color: #ffffff;
}
</style>
